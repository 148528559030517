import { jsonAuthor } from "./author"
import { useSite } from "../base/site"
import { jsonThumbnails } from "./image"

export function jsonSite() {
    const site = useSite()

    const url = site?.siteUrl

    return {
        "@context": "https://schema.org",
        "@type": "WebSite",
        author: jsonAuthor(),
        mainEntityOfPage: {
            "@type": "WebPage",
            "@id": url,
            url,
        },
        inLanguage: site?.lang,
        name: site?.title,
        description: site?.description,
        url,
        image: jsonThumbnails(),
    }
}
