import React from "react"
import { graphql, Link, PageProps } from "gatsby"
import { Kind } from "../../common/kind"
import { getListPath } from "../../common/path"
import { HomePageQuery } from "../../types/gatsby-graphql.generated"
import { compareByDate } from "../base/date"
import { localizeNew, useLocalization } from "../i18n/kind"
import { jsonSite } from "../json/site"
import { SinglePageHeadLayout, SinglePageLayout } from "../layouts/single"
import {
    InfoWith,
    ListItem,
    Message,
    HomePageSection,
    HomePageSectionHeader,
    HomePageSectionList,
} from "../views/content"
import { Markdown } from "../views/markdown"

const NEW_ARTICLES_LIMIT = 20 as const
const NEW_MEMOS_LIMIT = 10 as const

export function Head({ data }: PageProps<HomePageQuery>) {
    const { markdownRemark } = data

    if (!markdownRemark) {
        return null
    }

    const { title, description, path } = markdownRemark

    return (
        <SinglePageHeadLayout
            isHome={true}
            path={path}
            title={title}
            description={description}
            json={jsonSite()}
        />
    )
}

export default function HomePage({ data }: PageProps<HomePageQuery>) {
    const { markdownRemark, articles, memos } = data

    if (!markdownRemark) {
        return null
    }

    const { html } = markdownRemark

    return (
        <SinglePageLayout isHome={true}>
            <Markdown html={html} />
            {articles.nodes.length <= 0 ? null : (
                <HomePageSection>
                    <HomePageSectionHeader>
                        <h1>{localizeNew(Kind.Article)}</h1>
                    </HomePageSectionHeader>
                    <HomePageSectionList>
                        {articles.nodes
                            .sort(compareByDate)
                            .slice(0, NEW_ARTICLES_LIMIT)
                            .map(node => (
                                <ListItem
                                    key={node.path}
                                    path={node.path}
                                    title={node.title}
                                    info={<InfoWith {...node} />}
                                />
                            ))}
                    </HomePageSectionList>
                    {articles.nodes.length <= NEW_ARTICLES_LIMIT ? null : (
                        <Message>
                            <Link to={getListPath({ kind: Kind.Article })}>
                                {useLocalization().SEE_ALL}
                            </Link>
                        </Message>
                    )}
                </HomePageSection>
            )}
            {memos.nodes.length <= 0 ? null : (
                <HomePageSection>
                    <HomePageSectionHeader>
                        <h1>{localizeNew(Kind.Memo)}</h1>
                    </HomePageSectionHeader>
                    <HomePageSectionList>
                        {memos.nodes
                            .sort(compareByDate)
                            .slice(0, NEW_MEMOS_LIMIT)
                            .map(node => (
                                <ListItem
                                    key={node.path}
                                    path={node.path}
                                    title={node.title}
                                    info={<InfoWith {...node} />}
                                />
                            ))}
                    </HomePageSectionList>
                    {memos.nodes.length <= NEW_MEMOS_LIMIT ? null : (
                        <Message>
                            <Link to={getListPath({ kind: Kind.Memo })}>
                                {useLocalization().SEE_ALL}
                            </Link>
                        </Message>
                    )}
                </HomePageSection>
            )}
        </SinglePageLayout>
    )
}

export const pageQuery = graphql`
    query HomePage {
        markdownRemark(path: { eq: "/" }, published: { eq: true }) {
            contentKind
            contentId
            path
            title
            description
            updatedAt
            publishedAt
            html
        }
        articles: allMarkdownRemark(
            filter: {
                frontmatter: { kind: { eq: "article" } }
                published: { eq: true }
            }
        ) {
            nodes {
                contentKind
                contentId
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
                parentSeries {
                    path
                    title
                    items {
                        contentId
                    }
                }
            }
        }
        memos: allMarkdownRemark(
            filter: {
                frontmatter: { kind: { eq: "memo" } }
                published: { eq: true }
            }
        ) {
            nodes {
                contentKind
                contentId
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
            }
        }
    }
`
